// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming

// Added NOSONAR to skip the line in sonarqube as the rule for the new @use is not added in sonar
@use '~@angular/material' as mat; // NOSONAR
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$savvy-admin-webapp-primary: mat.define-palette(mat.$gray-palette,400,500);
$savvy-admin-webapp-accent: mat.define-palette(mat.$pink-palette,a200,a100,a400);

// The warn palette is optional (defaults to red).
$savvy-admin-webapp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$savvy-admin-webapp-theme: mat.define-light-theme(
    (
        color: (
            primary: $savvy-admin-webapp-primary,
            accent: $savvy-admin-webapp-accent,
            warn: $savvy-admin-webapp-warn,
        ),
    )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($savvy-admin-webapp-theme);

/* You can add global styles to this file, and also import other style files */

@import 'styles/themes/color-variables';
@import 'styles/themes/typography-variables.css';

/* Cel Components Resources */
@import "~@savvaslearning/cel-components/dist/cel-components/cel-components.css";

html,
body {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    height: calc(100% - 64px);
    font-family: var(--font-roboto), 'Helvetica Neue', sans-serif;
}

.app-drawer {
    flex-grow: 1;
}

.savvy-dialog {
    .dialog-wrapper.sc-cel-dialog {
        min-width: 500px;
    }

    .modal-content {
        display: flex;
        align-items: center;
        margin-left: 15px;
        word-break: break-word;
        user-select: text;

        span {
            margin: 0 25px 0 15px;
            overflow-wrap: break-word;
        }
    }
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
    background-color: var(--green);
}

.custom-panel-class {
    margin-top: 30px;
}

.mat-option.mat-option {
    height: 50px;
}

.mat-option-text.mat-option-text {
    line-height: normal;
    white-space: normal;
}

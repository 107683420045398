:root {
    /* Base Fonts **/
    --primary-font: 'Avenir';
    --font-cabin: 'Cabin';
    --font-icon: 'k12_universal_icons';
    --font-open-sans: 'Open Sans';
    --font-poppins: 'Poppins';
    --font-roboto: 'Roboto';

    /* Text Sizes */
    --text-size-xxxx-large: 1.75rem;   /* 1.75rem (28px) */
    --text-size-medium: 1.5rem;      /* 1.5rem (24px) */
    --text-size-xx-large: 1.375rem;     /* 1.375rem (22px) */
    --text-size-x-large: 1.25rem;       /* 1.25rem (20px) */
    --text-size-large: 1.125rem;        /* 1.125rem (18px) */
    --text-size-normal: 1rem;           /* 1rem (16px) */
    --text-size-small: 0.875rem;        /* 0.875rem (14px) */
    --text-size-x-small: 0.812rem;       /* 0.812rem (13px) */
    --text-size-xx-small: 0.75rem;      /* 0.75rem (12px) */

    /* Icon Sizes */
    --icon-size-xxxx-large: 6rem;       /* 6rem (96px) */
    --icon-size-xxx-large: 4.5rem;      /* 4.5rem (72px) */
    --icon-size-xx-large: 3.125rem;     /* 3.125rem (50px) */
    --icon-size-x-large: 2.25rem;       /* 2.25rem (36px) */
    --icon-size-large: 1.625rem;        /* 1.875rem (26px) */
    --icon-size-normal: 1.5rem;         /* 1.5rem (24px) */
    --icon-size-small: 1.125rem;        /* 1.125rem (18px) */
    --icon-size-x-small: 1rem;          /* 1rem (16px) */

    /* Layout Variables */
    --corner-radius-button: 2px;
    --corner-radius-input: 4px;
    --corner-radius-panel: 2px;
    --corner-radius-none: 0;
    --corner-radius-circle: 50%;
    --box-shadow: 0 1px 4px 0 rgba(0,0,0,0.3);
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semi-bold: 600;
    --font-weight-bold: 700;
    --font-weight-thin: 100;

    /* Letter Spacing Variables */
    --letter-spacing-xxx-small: 0.03125em;   /* 0.03125em; (0.5px) */
}

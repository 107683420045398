/* http://ux.pearsonschool.com/design/colors/realize/ */
:root {
    --grayDarker: #333;
    --grayDark: #666;
    --grayMed: #767676;
    --gray: #7b7b7b;
    --grayLightest: #eee;
    --grayWhite: #fafafa;
    --black: #000;
    --green: #008a00;
    --blue: #006be0;
    --blueViolet: #414bb2;
    --white: #fff;
}
